import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='Privacy Policy' description='Privacy Policy at Fresh Pets' keywords={['Fresh', 'Pets', 'Privacy', 'Policy']} mdxType="SEO" />
    <h1>{`Privacy Policy`}</h1>
    <h2>{`Data Protection`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Fresh Pets respects its clients' privacy and its committed to protecting all personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`A client record sheet containing the client's contact information and details about their pet will be kept by Fresh Pets.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Client record sheets can only be accessed by Fresh Pets staff.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Client record sheets will be deleted at the request of the client if they cease to use Fresh Pets' services.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Fresh Pets may retain information about any accidents, injuries or illnesses even after a client record sheet has been destroyed.`}</p>
      </li>
    </ol>
    <h2>{`Consent`}</h2>
    <p>{`By choosing Fresh Pets services you consent to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Fresh Pets holding my personal data (including my name, address, email, telephone contact number and details about my pet on our client record sheet).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Fresh Pets contacting me about grooming services.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      